import { useParams } from "react-router-dom";
import React, { useState, Component } from "react";
import { render } from "react-dom";
import "../styles.css";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle"
import NftTerminal from "./nftTerminal";
import { Canvas, useFrame } from '@react-three/fiber';
import { useConnect } from "../useConnect";
import { walletServices, connectProvides } from "@loopring-web/web3-provider";
import NftInteractive from "./nftInteractive";
import { Terminal } from "xterm";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  //primaryAccount,
  //setPrimaryAccount,
  primaryAccount,
  setConnectedWallet,
  setDisconnect,
  setPrimaryAccount,
  setWalletType,
} from "../terminalSlice";
import store from "../store";
import * as sdk from "@loopring-web/loopring-sdk";
const exchangeAPI = new sdk.ExchangeAPI({
  chainId: sdk.ChainId.MAINNET,
});
const userAPI = new sdk.UserAPI({ chainId: sdk.ChainId.MAINNET });
const walletAPI = new sdk.WalletAPI({ chainId: sdk.ChainId.MAINNET });
const nftAPI = new sdk.NFTAPI({ chainId: sdk.ChainId.MAINNET });
const globalAPI = new sdk.GlobalAPI({ chainId: sdk.ChainId.MAINNET });
/* const dispatch = useDispatch();

const metaMask = async (term) => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.MetaMask({});
  dispatch(setConnectedWallet("MetaMask"));
  dispatch(setWalletType(sdk.ConnectorNames.MetaMask));
};
const walletConnect = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.WalletConnect({ account: undefined, darkMode: true });
  dispatch(setConnectedWallet("WalletConnect"));
  dispatch(setWalletType(sdk.ConnectorNames.WalletConnect));
};
const coinbase = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.Coinbase({});
  dispatch(setConnectedWallet("Coinbase"));
  dispatch(setWalletType(sdk.ConnectorNames.Coinbase));
};
const gameStop = async () => {
  walletServices.sendDisconnect("", "should new provider").then((d) => {
    // console.log("gs disconnect");
  });
  await connectProvides.GameStop({});
  // console.log("gs dispatch");
  dispatch(setConnectedWallet("GameStop"));
  dispatch(setWalletType(sdk.ConnectorNames.Unknown));
};
const disconnect = () => {
  walletServices.sendDisconnect("", "disconnect");
  dispatch(setDisconnect());
}; */
class Main extends Component {
  constructor(props) {
    super(props);
    this.metaMaskConnect = this.metaMaskConnect.bind(this);
    this.gameStopConnect = this.gameStopConnect.bind(this);
    this.coinbaseConnect = this.coinbaseConnect.bind(this);
    this.walletConnectConnect = this.walletConnectConnect.bind(this);
    this.walletDisconnect = this.walletDisconnect.bind(this);
    this.primaryAcctDisplay = React.createRef();
    this.state = {//JSON.parse(window.localStorage.getItem('state')) ||
      primaryAccount: '',
      connectDisplay: 'Connect Wallet',
      showModal: false,
    }
  }
  //  setState(state) {
  //    window.localStorage.setItem('state', JSON.stringify(state));
  //   super.setState(state);
  // }

  loopstyle = {
    fill: '#1c60ff',
  }
  //[primaryAccount, setPrimaryAccount] = useState(0);

  async metaMaskConnect() {
    await this.props.onMetaMaskConnect();
  }
  async gameStopConnect() {
    await this.props.onGameStopConnect();

  }
  async coinbaseConnect(term) {
    await this.props.onCoinbaseConnect(term);
  }
  async walletConnectConnect(term) {
    await this.props.onWalletConnectConnect(term);
  }
  async walletDisconnect() {
    await this.props.onWalletDisconnect();
    this.state.primaryAccount = "";
    this.state.connectDisplay = "Connect Wallet";
    this.setState(this.state);
  }
  async viewWallet() {
    const { accInfo } = await exchangeAPI.getAccount({
      owner: this.state.primaryAccount,
    });
    //term.writeln("Done.");
    //term.write("Obtaining exchange info... ");
    const exchangeInfo = await exchangeAPI.getExchangeInfo();

    //term.writeln("Done.");
    //term.write("Generating eddsaKey... ");
    var kSeed =
      "Sign this message to access Loopring Exchange: " +
      exchangeInfo.exchangeInfo.exchangeAddress +
      " with key nonce: " +
      (accInfo.keyNonce - 1);

    const eddsaKey = await sdk.generateKeyPair({
      web3: connectProvides.usedWeb3,
      address: accInfo.owner,
      keySeed: kSeed, //accInfo.keySeed,
      walletType: this.state.walletType || "MetaMask",
      chainId: this.state.chainId || '1',
    });
    //term.writeln("Done.");
    //term.write("Obtaining apiKey... ");
    const { apiKey } = await userAPI.getUserApiKey(
      {
        accountId: accInfo.accountId,
      },
      eddsaKey.sk
    );

    //term.writeln("Done.");
    ///term.write("Obtaining token info... ");
    //const tokens = await exchangeAPI.getMixMarkets();
    //term.writeln("Done.\n\r");
    //console.log(tokens);
    const nftBalances = await userAPI.getUserNFTBalances(
      {
        accountId: accInfo.accountId,
      },
      apiKey
    );
    // console.log(nftBalances);

    if (nftBalances != null) {
      if ('userNFTBalances' in nftBalances && nftBalances.userNFTBalances != null) {
        this.state.nftList = [];
        let itemList = [];
        let itemIndex = 0;
        for (const item of nftBalances.userNFTBalances) {

          itemList.push(item.nftData);
        }
        const result = await nftAPI.getInfoForNFTTokens({
          nftDatas: itemList, //[item.nftData]
        });
        // console.log(`getInfoForNFTTokens: response: `, JSON.stringify(result));
        for (const nD of itemList) {
          //console.log(`item i: `, JSON.stringify(result[nD].nftId));
          try {
            const nftIdn = "https://gateway.pinata.cloud/ipfs/" + nftAPI.ipfsNftIDToCid(result[nD].nftId);
            const nftMeta = await fetch(nftIdn).then((response) => response.json());
            console.log(`item m: `, nftMeta);
            //term.writeln(nftIdn);
          } catch (exception) {
            console.log("Error: " + exception);
          }
          //itemList.push(item.nftData);
        }

        /*           const result = nftAPI.getContractNFTMeta({
                    web3: connectProvides.usedWeb3,
                    tokenAddress: item.tokenAddress,
                    nftId: item.nftId,
                    nftType: sdk.NFTType.ERC1155,
                  }).then(result => {
                    console.log("nftdata " + JSON.stringify(result));
                    console.log("nftImgA: " + result.image);
                    let nftImg = result.image.replace('ipfs://', 'http://octagonnft.cloud:8080/ipfs/');
                    console.log("nftImgB: " + nftImg);
                    document.getElementById(item.tokenId).innerHTML = '                <div className="col-3 nftcontainer">'
                      + '<div className="nftbox" style="background-image: url(\'' + nftImg + '\'); background-color: #000000;">'
                      + ' </div>'
                      + '  <div className="nftdesc rounded-bottom">'
                      + '    <strong>' + result.name + '</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x5cba2f44ff006b6f6b83cd8d19c7375eb7a4f2e2b82823758a8615f9a4958bef-2" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">'
                      + '      <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"></path>'
                      + '      <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"></path>'
                      + '    </svg></a></div>'
                      + '    <p></p>'
                      + '  </div>'
                      + ' </div>';
                  }).catch((ex) => { console.log("err: " + ex); }); */

        // itemList.push(<div key={itemIndex}>#{itemIndex} {item.nftId}</div>);
        //  this.state.nftList.push();
        //  itemIndex++;

        // nftBalances.userNFTBalances.forEach((item,index)=>{



        //})

      }

    }









    this.state.nftBalances = nftBalances;

    this.setState(this.state);
  }


  render() {
    function NFTList(props) {
      // const nftBal = JSON.parse();
      if ('balances' in props && props.balances != null) {
        if ('userNFTBalances' in props.balances && props.balances.userNFTBalances != null) {
          let itemList = [];
          props.balances.userNFTBalances.forEach((item, index) => {
            console.log("itemd: " + JSON.stringify(item));
            itemList.push(

              <div key={index} id={item.tokenId}>#{index} {item.nftId}  {item.name}</div>



            )
          })


          return <div><h1>{props.balances.totalNum} NFTs:</h1> {itemList}</div>;
        }

      }
      return "";


    }
    function ModalViewer(props) {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const url = "url('" + props.url + "')";
      const title = props.title;
      const desc = props.desc;
      return (
        <>
          <div className="col-3 robonftcontainer">
            <div className="robonftbox" style={{ backgroundImage: url, cursor: 'pointer' }} onClick={handleShow}>
              <div className="roboindi"><div className="roboloopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div></div>
            </div>
            <div className="nftdesc rounded-bottom">
              <strong>{props.title}</strong>
              <p>{props.desc}</p>
            </div>

          </div>


          <Modal show={show} onHide={handleClose} animation={false} fullscreen={true} className="robomodal">
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body><center><img src={props.url} style={{ height: "100%" }} /></center></Modal.Body>
            <Modal.Footer>
              <p className="text-left">{props.desc}</p>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        </>
      );
    }
    return (
      <React.Fragment>
        <div className="container">
          <div className="row justify-content-md-center">

            <div className="col-11 pt-2 pr-5"><h2 className="text-right">ROBOTECH NFT Collection (Preview)</h2>

              <h5 className="text-right">Futuristic virtual components for robotic, cybernetic, and encabulator projects</h5></div>
            <h5 className="text-left explore-robotech">Explore the ROBOTECH NFT parts catalog:</h5>
            <ModalViewer url="/img/ROBOTECH/ocularsensor1024.webp" title="ROBOTECH | Ocular Sensor" desc="Directional photon collector" />
            <ModalViewer url="/img/ROBOTECH/transformdrive1024.webp" title="ROBOTECH | Transform Drive" desc="Four-dimensional morphing coupler" />
            <ModalViewer url="/img/ROBOTECH/magicsmoke1024.webp" title="ROBOTECH | Magic Smoke" desc="Pre-charged electromagical capacitor" />
            <ModalViewer url="/img/ROBOTECH/thermocoil1024.webp" title="ROBOTECH | Thermo Coil" desc="Thermodynamic equilibrium in a single package" />







            <br /><br />
            <div className="col-11 pt-5 pr-5"><h2 className="text-right">Dynamic NFTs</h2></div>
            {/* 
            <div className="col-3 nftcontainer">


              <iframe src="https://octagonnft.cloud/StickyNotes/purple" height="300"></iframe>
              <div className="nftdesc rounded-bottom">
                <strong>StickyNotes - OctagonNFT</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x19822bc2508f1b989ce52bb02671df30ca1fa0dabaa6cb798aaeefd1820b035e-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>StickyNotes - OctagonNFT (config top right)<br />Available in <a href="https://octagonnft.cloud/StickyNotes/yellow" target="_blank">Yellow</a>, <a href="https://octagonnft.cloud/StickyNotes/blue" target="_blank">Blue</a>, <a href="https://octagonnft.cloud/StickyNotes/green" target="_blank">Green</a>, and <a href="https://octagonnft.cloud/StickyNotes/purple" target="_blank">Purple</a></p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: '#1c60ff' }} /></svg>&nbsp;Loopring L2</div><div className="avail">99994 Available - 20 LRC</div></div>


 */}













            <div className="col-3 nftcontainer">


              <iframe src="https://octagonnft.cloud/NFTTerminal/" height="300"></iframe>
              <div className="nftdesc rounded-bottom">
                <strong>NFT Terminal for Loopring L2 - @OctagonNFT</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x8b9a501168e3f15afc0aab1e6960d4457b221d6d75635484a94fbdcba6af0bf8-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>NFT Terminal for Loopring L2 - @OctagonNFT</p>

              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: '#1c60ff' }} /></svg>&nbsp;Loopring L2</div><div className="avail">99951 Available - 80 LRC</div></div>





            <div className="col-3 nftcontainer">
              <div className="nftbox" style={{ backgroundImage: "url('/img/swordofrocketpower.webp')" }}>
              </div>
              <div className="nftdesc rounded-bottom">
                <strong>Sword of Rocket Power - OctagonNFT</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0xb6f0bdbcd8d81d88fd092d76484fd2712d3d16a54ef6daf65a1f89d440d56534-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>Sword of Rocket Power - OctagonNFT</p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">347 Available - 10 LRC</div></div>




            {/* 

            <div className="col-3 nftcontainer">
              <div className="nftbox" style={{ backgroundImage: "url('https://nftbooks.cloud/OctagonNFT/DynamicGS/img.jpg')" }}>
              </div>
              <div className="nftdesc rounded-bottom">
                <strong>DynamicGS</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x3c139464f102208aecdfa50919500e273f07b2ee0ca89b3f7f1fe1aff386f260-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" /></svg></a></div>
                <p>Red when GME is down, Green when GME is up. Updates every 15 minutes. - @OctagonNFT</p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">5462 Available - 40 LRC</div></div>

 */}

            <div className="col-3 nftcontainer">


              <object type="image/svg+xml" id="magic" data="https://nftbooks.cloud/OctagonNFT/Magic8Ball/Magic8Ball.svg" height="300"></object>
              <div className="nftdesc rounded-bottom">
                <strong>Dynamic Magic 8 Ball</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x5ef4269db022d9911281b2331d061ec21fd0b63edca05a053ebbd47e2162291d-2" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>Magic 8 Ball - Dynamic Loopring L2 NFT - @OctagonNFT</p>
              </div>






            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">9449 Available - 20 LRC</div></div>




            {/*            <div className="col-3 nftcontainer">
              <div className="nftbox" style={{ backgroundImage: "url('https://nftbooks.cloud/OctagonNFT/DynamicGME-RAGE/img.jpg')" }}>
              </div>
              <div className="nftdesc rounded-bottom">
                <strong>DynamicGME-RAGE</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x5cba2f44ff006b6f6b83cd8d19c7375eb7a4f2e2b82823758a8615f9a4958bef-2" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>Red when GME is down, Green when GME is up. Updates every 15 minutes.</p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">8598 Available - 20 LRC</div></div>
 */}
            <div className="col-3 nftcontainer">
              <div className="nftbox" style={{ backgroundImage: "url('https://nftbooks.cloud/OctagonNFT/TheUtilityOfNFT/main.webp')" }}>
              </div>
              <div className="nftdesc rounded-bottom">
                <strong>The Utility of NFT</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x859eec1bcad3fa20a953059f9e700ff59b29226b7b47a3427367d182c3b03933-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a><br /><a href="https://nftbooks.cloud/OctagonNFT/TheUtilityOfNFT" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-book-half" viewBox="0 0 16 16">
                  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg></a></div>
                <p>A book on The Utility of NFT</p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">4467 Available - 20 LRC</div></div>


            {/* 
            <div className="col-3 nftcontainer">
              <div className="nftbox" style={{ backgroundImage: "url('https://nftbooks.cloud/OctagonNFT/DynamicGME/img.jpg')" }}>
              </div>
              <div className="nftdesc rounded-bottom">
                <strong>DynamicGME</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0xaf2c21b6548131517448054c5e738486278dfa150d95c1f861c0a0aeeb062fef-2" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg></a></div>
                <p>Red when GME is down, Green when GME is up. Updates every 15 minutes.</p>
              </div>
            </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={{ fill: "#1c60ff" }} /></svg>&nbsp;Loopring L2</div><div className="avail">156 Available - 60 LRC</div></div>


 */}




            <div className="col-md-3 col-xs-4 col-sm-4 order rounded shadow">

              <div className="text-center"><h3>Collect These NFTs</h3></div>
              <ol>
                <li>Send LRC (on L2) to octagonnft.eth</li>
                <li>Let us know which NFTs you are collecting (in the memo, Twitter, or Discord OctagonNFT#6317)</li>
                <li>We'll send them back!</li>
              </ol>
              <br /><br />
            </div>






            {/* <NFTList balances={this.state.nftBalances} /> */}



          </div ><br /><br /><br /><br />
        </div >


        <nav className="navbar fixed-bottom navbar-expand navbar-dark navbg">
          <div className="container mt-0">

            <a className="navbar-brand" href="/">
              <img src="/img/octogonnft300.gif" height="60px" />
            </a>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto">
                <li className="nav-item active">
                  <a
                    href="https://twitter.com/OctagonNFT"
                    className="nav-link"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                    </svg>{" "}
                    @OctagonNFT
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link disabled" href="#">
                    octagonnft.eth
                  </a>
                </li>

              </ul>
              {/*               <ul className="navbar-nav mr-auto">

                <li className="nav-item dropup">
                  <a className="nav-link dropdown-toggle text-right p-0" href="#" id="dropdown10" data-bs-toggle="dropdown" aria-expanded="false">{this.state.connectDisplay}</a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown10">
                    <li><a className="dropdown-item" href="#" onClick={this.metaMaskConnect}>MetaMask</a></li>
                    <li><a className="dropdown-item" href="#" onClick={this.gameStopConnect}>GameStop</a></li>
                    <li><a className="dropdown-item" href="#" onClick={this.walletDisconnect}>Disconnect</a></li>
                  </ul>
                  <a className="nav-link disabled p-0" href="#">{this.state.primaryAccount}</a>
                </li>
              </ul> */}
            </div>
          </div>

        </nav>
      </React.Fragment >
    );
  }
  componentDidMount() {


    var currentState;
    var lastError;
    store.subscribe(() => {
      var newState = store.getState();
      //
      if (newState != currentState) {
        currentState = newState;
        if (
          currentState.terminal.commandResult.length > 0 &&
          currentState.terminal.commandResult[0].startsWith("Connected:")

        ) {
          // clearCommandResult();
          console.log(currentState.terminal.commandResult[0]);
          console.log(
            currentState.terminal.connectedWallet +
            " " +
            currentState.terminal.commandResult[0]
          );
          /*           this.state = JSON.parse(window.localStorage.getItem('state')) || {
                      primaryAccount: urrentState.terminal.commandResult[1] || "",
                      connectDisplay: 'Connected',
                    } */
          this.state.connectDisplay = "Connected";
          this.state.primaryAccount = currentState.terminal.commandResult[1];
          this.setState(this.state);
          this.viewWallet();
          // this.primaryAcctDisplay.current = currentState.terminal.commandResult[1];
          // setPrimaryAccount(currentState.terminal.commandResult[1]);
          // this.primaryAcctDisplay.current.
          //term.writeln(currentState.terminal.commandResult[1]);
          //term.prompt(term);
        }

        if (
          currentState.terminal.commandResult.length > 0 &&
          currentState.terminal.commandResult[0].startsWith("Error:")
        ) {
          if (lastError != currentState.terminal.commandResult[1]) {
            lastError = currentState.terminal.commandResult[1];
            // clearCommandResult();

            console.log(
              "Error: " + JSON.stringify(currentState.terminal.commandResult[1])
            );
            if (
              //currentState.terminal.commandResult[1].opts.connectName ==
              //  "GameStop" &&
              currentState.terminal.commandResult[1].opts.error.code == 700004
            ) {
              console.log("User not installed GameStop extension");

            }
            //term.prompt(term);
          }
        }
      }
    });
    // this.viewWallet();
  }

}
export default Main;