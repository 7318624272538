import { createSlice } from "@reduxjs/toolkit";
import * as sdk from "@loopring-web/loopring-sdk";

export const terminalSlice = createSlice({
  name: "terminal",
  initialState: {
    value: 0,
    primaryAccount: "",
    connectedWallet: "",
    commandResult: [],
    walletType: sdk.ConnectorNames.MetaMask,
    chainId: sdk.ChainId.MAINNET,
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setPrimaryAccount: (state, action) => {
      state.primaryAccount = action.payload;
    },
    setConnectedWallet: (state, action) => {
      state.connectedWallet = action.payload;
    },
    setWalletType: (state, action) => {
      state.walletType = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    setCommandResult: (state, action) => {
      //if (state.commandResult != action.payload) {
      //  state.value += 1;
      //}

      state.commandResult = action.payload;
    },
    clearCommandResult: () => {
      state.commandResult = [];
    },
    setDisconnect: (state) => {
      state.connectedWallet = "";
      state.primaryAccount = "";
      state.commandResult = [];
      state.walletType = sdk.ConnectorNames.Unknown;
    },
  },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  setPrimaryAccount,
  setCommandResult,
  setConnectedWallet,
  clearCommandResult,
  setDisconnect,
  setWalletType,
  setChainId,
} = terminalSlice.actions;
export const primaryAccount = (state) => state.primaryAccount;
export const commandResult = (state) => state.commandResult;
export default terminalSlice.reducer;