import React, { Component } from "react";
import { render } from "react-dom";
import "./styles.css";
import { useConnect } from "./useConnect";
import { walletServices, connectProvides } from "@loopring-web/web3-provider";
import NftTerminal from "./components/nftTerminal";
import NFTGIF from './nftterminal.gif';
import { Terminal } from "xterm";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  setPrimaryAccount,
  primaryAccount,
  setConnectedWallet,
  setDisconnect,
  setWalletType,
} from "./terminalSlice";
import store from "./store";
import * as sdk from "@loopring-web/loopring-sdk";
import queryString from "query-string";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Guide from "./components/guide";
import Main from "./components/main";
//var primaryAccount = useSelector((state) => state.counter.value);
export default function App() {
  useConnect();
  const dispatch = useDispatch();

  const metaMask = async (term: Terminal) => {
    walletServices.sendDisconnect("", "should new provider");
    await connectProvides.MetaMask({});
    dispatch(setConnectedWallet("MetaMask"));
    dispatch(setWalletType(sdk.ConnectorNames.MetaMask));
  };
  const walletConnect = async () => {
    walletServices.sendDisconnect("", "should new provider");
    await connectProvides.WalletConnect({ account: undefined, darkMode: true });
    dispatch(setConnectedWallet("WalletConnect"));
    dispatch(setWalletType(sdk.ConnectorNames.WalletConnect));
  };
  const coinbase = async () => {
    walletServices.sendDisconnect("", "should new provider");
    await connectProvides.Coinbase({});
    dispatch(setConnectedWallet("Coinbase"));
    dispatch(setWalletType(sdk.ConnectorNames.Coinbase));
  };
  const gameStop = async () => {
    walletServices.sendDisconnect("", "should new provider").then((d) => {
      // console.log("gs disconnect");
    });
    await connectProvides.GameStop({});
    // console.log("gs dispatch");
    dispatch(setConnectedWallet("GameStop"));
    dispatch(setWalletType(sdk.ConnectorNames.Unknown));
  };
  const disconnect = () => {
    walletServices.sendDisconnect("", "disconnect");
    dispatch(setDisconnect());
  };
  const queryParams = queryString.parse(window.location.search);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Main
                onWalletDisconnect={disconnect}
                onMetaMaskConnect={metaMask}
                onGameStopConnect={gameStop}
                onWalletConnectConnect={walletConnect}
                onCoinbaseConnect={coinbase}
              />
            }
          />
          <Route
            path="NFTTerminal/"
            element={
              <NftTerminal
                onWalletDisconnect={disconnect}
                onMetaMaskConnect={metaMask}
                onGameStopConnect={gameStop}
                onWalletConnectConnect={walletConnect}
                onCoinbaseConnect={coinbase}
              />
            }
          />
          <Route
            path="NFTTerminal/guide/"
            element={
              <Guide
                onWalletDisconnect={disconnect}
                onMetaMaskConnect={metaMask}
                onGameStopConnect={gameStop}
                onWalletConnectConnect={walletConnect}
                onCoinbaseConnect={coinbase}
              />
            }
          />

          <Route
            path="/NFTTerminal/NFTTERMINAL.gif"
            element={<Navigate replace to="/img/NFTTerminal/NFTTERMINAL.gif" />}
          />

        </Routes>
      </BrowserRouter>

      {/* <h1>Hello React</h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
              <button onClick={metaMask}>
          <img
            src="https://static.loopring.io/assets/svg/meta-mask.svg"
            alt="MetaMask"
            height="36"
          />
        </button> 
        <button onClick={walletConnect}>
          <img
            src="https://static.loopring.io/assets/svg/wallet-connect.svg"
            alt="walletConnect"
            height="36"
          />
        </button>
        <button onClick={coinbase}>
          <img
            src="https://static.loopring.io/assets/svg/coinbase-wallet.svg"
            alt="MetaMask"
            height="36"
          />
        </button>
        <button onClick={disconnect}>disconnect</button>
      </div> */}
    </div>
  );
}
