import { useParams } from "react-router-dom";
import React, { Component } from "react";
import { render } from "react-dom";
import "../styles.css";

import "bootstrap/dist/css/bootstrap.min.css";
import NftTerminal from "./nftTerminal";

import { useConnect } from "../useConnect";
import { walletServices, connectProvides } from "@loopring-web/web3-provider";

import { Terminal } from "xterm";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  setPrimaryAccount,
  primaryAccount,
  setConnectedWallet,
  setDisconnect,
  setWalletType,
} from "../terminalSlice";
import * as sdk from "@loopring-web/loopring-sdk";
/* const dispatch = useDispatch();

const metaMask = async (term) => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.MetaMask({});
  dispatch(setConnectedWallet("MetaMask"));
  dispatch(setWalletType(sdk.ConnectorNames.MetaMask));
};
const walletConnect = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.WalletConnect({ account: undefined, darkMode: true });
  dispatch(setConnectedWallet("WalletConnect"));
  dispatch(setWalletType(sdk.ConnectorNames.WalletConnect));
};
const coinbase = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.Coinbase({});
  dispatch(setConnectedWallet("Coinbase"));
  dispatch(setWalletType(sdk.ConnectorNames.Coinbase));
};
const gameStop = async () => {
  walletServices.sendDisconnect("", "should new provider").then((d) => {
    // console.log("gs disconnect");
  });
  await connectProvides.GameStop({});
  // console.log("gs dispatch");
  dispatch(setConnectedWallet("GameStop"));
  dispatch(setWalletType(sdk.ConnectorNames.Unknown));
};
const disconnect = () => {
  walletServices.sendDisconnect("", "disconnect");
  dispatch(setDisconnect());
}; */
class NftInteractive extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
                  <div className="col-3 nftcontainer">
				
				
        <iframe src="https://octagonnft.cloud/StickyNotes/purple" height="300"></iframe>
        <div className="nftdesc rounded-bottom">
          <strong>StickyNotes - OctagonNFT</strong><div className="nftlink"><a href="https://lexplorer.io/nfts/0xb69cbe2ff674cf98c745754b0ab2f1c24471889a-0-0x8e253f32289062a366fae6854a9bc9b41719685b-0x19822bc2508f1b989ce52bb02671df30ca1fa0dabaa6cb798aaeefd1820b035e-1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
<path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
<path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
</svg></a></div>
          <p>StickyNotes - OctagonNFT (config top right)<br />Available in <a href="https://octagonnft.cloud/StickyNotes/yellow" target="_blank">Yellow</a>, <a href="https://octagonnft.cloud/StickyNotes/blue" target="_blank">Blue</a>, <a href="https://octagonnft.cloud/StickyNotes/green" target="_blank">Green</a>, and <a href="https://octagonnft.cloud/StickyNotes/purple" target="_blank">Purple</a></p>
        </div>
      </div><div className="indi"><div className="loopl2"><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.9 30"><title>loopring-lrc-logo</title><path id="Path" d="M 12.3,17.4 V 30 H 12.2 L 0.2,10.3 8,20.1 Z M 23.9,10 H 0 L 8,0 Z" style={this.loopstyle}/></svg>&nbsp;Loopring L2</div><div className="avail">99998 Available - 20 LRC</div></div>


      </React.Fragment>
    );
  }
  componentDidMount() {}
}
export default NftInteractive;