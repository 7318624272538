import React from "react";
import { render } from "react-dom";
//import * as ReactDOMClient from 'react-dom';

//import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import App from "./App";
//import { XTerm } from "xterm-for-react";
//import { FitAddon } from "xterm-addon-fit";
//import { WebglAddon } from "xterm-addon-webgl";
import store from "./store";
import { Provider } from "react-redux";
import NftTerminal from "./components/nftTerminal";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  setPrimaryAccount,
  primaryAccount,
} from "./terminalSlice";
import { BrowserRouter } from "react-router-dom";
//const fitAddon = new FitAddon();
//const webglAddon = new WebglAddon();
const rootElement = document.getElementById("root");
//const root = ReactDOMClient..createRoot(rootElement);
//render(<XTerm addons={[fitAddon, webglAddon]} />, rootElement);
//render(<NftTerminal />, rootElement);
//const ethElement = document.getElementById("eth");
render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);
//NftTerminal.
//var term = new Terminal.Terminal();

/*  var term = new Terminal({
    fontFamily: '"Cascadia Code", Menlo, monospace',
    fontSize: 12,
    // theme: baseTheme,
    cursorBlink: true,
    cols: 60,
    rows: 20,
  });


  const termElement = document.getElementById("terminal");
 if (termElement != null){
  term.open(termElement);
  const shellprompt = "# ";

 
 } 
 const terminalPrompt()  {
    term.write("\r\n" + shellprompt);
  } */
