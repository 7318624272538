import { useParams } from "react-router-dom";
import React, { Component } from "react";
import { render } from "react-dom";
import "../styles.css";

import "bootstrap/dist/css/bootstrap.min.css";
import NftTerminal from "./nftTerminal";

import { useConnect } from "../useConnect";
import { walletServices, connectProvides } from "@loopring-web/web3-provider";

import { Terminal } from "xterm";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  setPrimaryAccount,
  primaryAccount,
  setConnectedWallet,
  setDisconnect,
  setWalletType,
} from "../terminalSlice";
import * as sdk from "@loopring-web/loopring-sdk";
/* const dispatch = useDispatch();

const metaMask = async (term) => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.MetaMask({});
  dispatch(setConnectedWallet("MetaMask"));
  dispatch(setWalletType(sdk.ConnectorNames.MetaMask));
};
const walletConnect = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.WalletConnect({ account: undefined, darkMode: true });
  dispatch(setConnectedWallet("WalletConnect"));
  dispatch(setWalletType(sdk.ConnectorNames.WalletConnect));
};
const coinbase = async () => {
  walletServices.sendDisconnect("", "should new provider");
  await connectProvides.Coinbase({});
  dispatch(setConnectedWallet("Coinbase"));
  dispatch(setWalletType(sdk.ConnectorNames.Coinbase));
};
const gameStop = async () => {
  walletServices.sendDisconnect("", "should new provider").then((d) => {
    // console.log("gs disconnect");
  });
  await connectProvides.GameStop({});
  // console.log("gs dispatch");
  dispatch(setConnectedWallet("GameStop"));
  dispatch(setWalletType(sdk.ConnectorNames.Unknown));
};
const disconnect = () => {
  walletServices.sendDisconnect("", "disconnect");
  dispatch(setDisconnect());
}; */
class Guide extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-4">

              <NftTerminal
                onWalletDisconnect={this.props.onWalletDisconnect}
                onMetaMaskConnect={this.props.onMetaMaskConnect}
                onGameStopConnect={this.props.onGameStopConnect}
                onWalletConnectConnect={this.props.onWalletConnectConnect}
                onCoinbaseConnect={this.props.onCoinbaseConnect}
              />

              <h5 className="pt-5">Terminal Overview</h5>
              <ol>
                <li>
                  <a href="#connect">Connect Wallets</a>
                </li>
                <li>
                  <a href="#balances">Check Balances</a>
                </li>
                <li>
                  <a href="#mint">Mint NFTs</a>
                </li>
                <li>
                  <a href="#transfer">Transfer NFTs</a>
                </li>
                <li>
                  <a href="#help">Help</a>
                </li>
              </ol>
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 pt-8">

              <h1>NFT Terminal</h1>
              <h2>for Loopring L2</h2>
              <div className="card text-white bg-dark2 mt-5">

                <div className="card-body">
                  <p className="card-text">
                    NFT Terminal for Loopring L2 is a command line terminal connected to the <a href="https://loopring.github.io/loopring_sdk/" target="_blank">Loopring SDK</a>. Use it to check balances, see <a href="https://loopring.io" target="_blank">loopring.io</a> exchange info, mint and transfer NFTs, see debug info, and more!
                  </p>
                  <p>You can get the NFT Terminal in your wallet as a NFT which will unlock future features and functionality. </p>

                </div>
              </div>



              <div className="card text-white bg-dark2 mt-5">
                <h3 className="card-header" id="connect">
                  Connect Wallets
                </h3>
                <div className="card-body">
                  <p className="card-text">
                    Use one of the wallet commands to connect your wallet:
                  </p>
                  <ul>
                    <li>
                      <code>gamestop</code>
                    </li>
                    <li>
                      <code>metamask</code>
                    </li>
                    <li>
                      <code>coinbase</code>
                    </li>
                    <li>
                      <code>walletconnect</code>
                    </li>
                  </ul>
                  <p>
                    Use the <code>primary</code> command to get your primary
                    account address.
                  </p>
                  <img src="/img/NFTTerminal/gsprimary.png" />
                </div>
              </div>
              <div className="card text-white bg-dark2 mt-5">
                <h3 className="card-header" id="balances">
                  Check Balances
                </h3>
                <div className="card-body">
                  <p className="card-text">
                    Use the <code>balances</code> command to list your NFTs (returns 20 NFTs max) and
                    LRC/ETH balances:
                  </p>

                  <img src="/img/NFTTerminal/balances.png" />
                </div>
              </div>

              <div className="card text-white bg-dark2 mt-5">
                <h3 className="card-header" id="mint">
                  Mint NFTs
                </h3>
                <div className="card-body">
                  <p className="card-text">
                    The <code>mint</code> command is equivalent to Loopring.io
                    Advance Create.
                  </p>
                  <ol>
                    <li>
                      Upload media files and a metadata.json file to IPFS (
                      <a
                        href="https://loopring.io/#/document/mint_nft.md"
                        target="_blank"
                      >
                        Loopring Docs
                      </a>
                      )
                    </li>
                    <li>Connect your wallet</li>
                    <li>
                      Use the <code>mint</code> command to mint NFTs:
                    </li>
                  </ol>
                  <img src="/img/NFTTerminal/mint.png" />
                </div>
              </div>

              <div className="card text-white bg-dark2 mt-5">
                <h3 className="card-header" id="transfer">
                  Transfer NFTs
                </h3>
                <div className="card-body">
                  <p className="card-text">
                    Identify a NFT tokenID using the <code>balances</code> command and use the <code>transferNFT</code> command to transfer NFTs to another L2 address:
                  </p>

                  <img src="/img/NFTTerminal/transfer.png" />
                </div>
              </div>


              <div className="card text-white bg-dark2 mt-5">
                <h3 className="card-header" id="help">
                  Help
                </h3>
                <div className="card-body">
                  <p className="card-text">
                    Use the <code>help</code> command to see all of the available commands:
                  </p>

                  <img src="/img/NFTTerminal/help.png" />
                </div>
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <nav className="navbar fixed-bottom navbar-expand navbar-dark navbg">
          <div className="container mt-0">
            <a className="navbar-brand" href="/">
              <img src="/img/octogonnft300.gif" height="60px" />
            </a>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <a
                    href="https://twitter.com/OctagonNFT"
                    className="nav-link"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                    </svg>{" "}
                    @OctagonNFT
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link disabled" href="#">
                    octagonnft.eth
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
  componentDidMount() { }
}
export default Guide;