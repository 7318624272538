import React from "react";
import { ChainId, useConnectHook } from "./useConnectHook";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  setPrimaryAccount,
  setCommandResult,
} from "./terminalSlice";

export function useConnect() {
  // const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch();
  useConnectHook({
    handleAccountDisconnect: () => {
      // console.log("handleAccountDisconnect:");
      // await sleep(REFRESH_RATE)
    },
    handleProcessing: ({ opts }: { type: any; opts: any }) => {
      console.log(opts);
    },
    handleError: (props: { type: any; opts?: any }) => {
      console.log("Error:", props);
      dispatch(setCommandResult(["Error:", props]));
    },
    handleConnect: async ({
      accounts,
      chainId,
    }: {
      accounts: string;
      provider: any;
      chainId: ChainId | "unknown";
    }) => {
      const accAddress = accounts[0];
      dispatch(setPrimaryAccount(accAddress));
      dispatch(setCommandResult(["Connected:", accAddress]));
      // console.log("connect event", accounts);
      // dispatch(increment());
      //  console.log(
      //    "After connect >>,network part start: step1 networkUpdate",
      //  accAddress
      // );
      //console.log("After connect >>,network part done: step2 check account");
    },
  });
}
