import React, { Component } from "react";
import { render } from "react-dom";
//mport Terminal from "xterm";
import { Terminal, ITheme } from "xterm";
import "xterm/css/xterm.css";
//import { XTerm } from "xterm-for-react";
//import { ITerminalOptions, ITerminalAddon } from "xterm";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FitAddon } from "xterm-addon-fit";
import { WebglAddon } from "xterm-addon-webgl";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  primaryAccount,
  clearCommandResult,
  setDisconnect,
} from "../terminalSlice";
import store from "../store";
import * as sdk from "@loopring-web/loopring-sdk";
//import OffchainNFTFeeReqType from loo
//import * as sdkdef from "../../node_modules/loopring-sdk/dist/defs/index";
import Web3 from "web3";

import { walletServices, connectProvides } from "@loopring-web/web3-provider";
import { async } from "rxjs";
import { getNftData, get_EddsaSig_NFT_Mint } from "@loopring-web/loopring-sdk";
class promptQuestion {
  static question = "";
  static response = "";
}
/* export class LoopringAPIClass {
  static userAPI;
  static exchangeAPI;
  static ammpoolAPI;
  static walletAPI;
  static wsAPI;
  static nftAPI;
  static delegate;
  static globalAPI;
  static contractAPI;
  static __chainId__;
  static InitApi = (chainId) => {
    this.userAPI = new sdk.UserAPI({ chainId });
    this.exchangeAPI = new sdk.ExchangeAPI({ chainId });
    // this.
    this.ammpoolAPI = new sdk.AmmpoolAPI({ chainId });
    this.walletAPI = new sdk.WalletAPI({ chainId });
    this.wsAPI = new sdk.WsAPI({ chainId });
    //this.nftAPI = new sdk.NFTAPI({ chainId });
    //this.delegate = new sdk.DelegateAPI({ chainId });
    this.__chainId__ = chainId;
    //this.contractAPI = sdk.ContractAPI;
  };
} */

const exchangeAPI = new sdk.ExchangeAPI({
  chainId: sdk.ChainId.MAINNET,
});
const userAPI = new sdk.UserAPI({ chainId: sdk.ChainId.MAINNET });
const walletAPI = new sdk.WalletAPI({ chainId: sdk.ChainId.MAINNET });
const nftAPI = new sdk.NFTAPI({ chainId: sdk.ChainId.MAINNET });
const globalAPI = new sdk.GlobalAPI({ chainId: sdk.ChainId.MAINNET });
//const LoopringAPI = LoopringAPIClass.InitApi(sdk.ChainId.MAINNET);
//import { useConnect } from "../useConnect";

//import { walletServices, connectProvides } from "@loopring-web/web3-provider";
//import "xterm/lib/xterm";
class NftTerminal extends Component {
  constructor(props) {
    super(props);

    this.metaMaskConnect = this.metaMaskConnect.bind(this);
    this.gameStopConnect = this.gameStopConnect.bind(this);
    this.coinbaseConnect = this.coinbaseConnect.bind(this);
    this.walletConnectConnect = this.walletConnectConnect.bind(this);
    this.walletDisconnect = this.walletDisconnect.bind(this);
    // Create a ref
    this.xtermRef = React.createRef();
    this.loopRef = React.createRef();
  }

  styles = {
    nft: {
      width: "300px",
    },
  };

  async metaMaskConnect(term) {
    await this.props.onMetaMaskConnect(term);
  }
  async gameStopConnect(term) {
    await this.props.onGameStopConnect(term);
  }
  async coinbaseConnect(term) {
    await this.props.onCoinbaseConnect(term);
  }
  async walletConnectConnect(term) {
    await this.props.onWalletConnectConnect(term);
  }
  async walletDisconnect(term) {
    await this.props.onWalletDisconnect(term);
  }

  render() {
    return (
      <React.Fragment>
        <div id="nftterm" style={this.styles.nft}>
          <div id="terminal"></div>
        </div>
      </React.Fragment>
    );
  }
  componentDidMount() {
    var baseTheme = {
      foreground: "#F8F8F8",
      background: "#0d0d0d",
      selection: "#5DA5D533",
      black: "#1E1E1D",
      brightBlack: "#262625",
      red: "#CE5C5C",
      brightRed: "#FF7272",
      green: "#5BCC5B",
      brightGreen: "#72FF72",
      yellow: "#CCCC5B",
      brightYellow: "#FFFF72",
      blue: "#5D5DD3",
      brightBlue: "#7279FF",
      magenta: "#BC5ED1",
      brightMagenta: "#E572FF",
      cyan: "#5DA5D5",
      brightCyan: "#72F0FF",
      white: "#F8F8F8",
      brightWhite: "#FFFFFF",
    };
    this.xtermRef.current = new Terminal({
      fontFamily: "Cascadia Mono, Monospace, Lucida Console",
      fontSize: 12,
      theme: baseTheme,
      cursorBlink: true,
      cols: 80,
      rows: 20,
    });

    var term = this.xtermRef.current;

    // () => {
    //  const dispatch = useDispatch();
    //   dispatch(setTermial(term));
    // };
    //loop.disconnect();

    term.open(document.getElementById("terminal"));
    // Once the terminal is loaded write a new line to it.
    /* const term = this.xtermRef.current.terminal;
    term.registerLinkProvider({
      provideLinks(bufferLineNumber, callback) {
        switch (bufferLineNumber) {
          case 10:
            callback([
              {
                text: "OctagonNFT",
                range: { start: { x: 27, y: 10 }, end: { x: 36, y: 10 } },
                activate() {
                  window.open("https://OctagonNFT.cloud", "_blank");
                },
              },
            ]);
            return;
        }
        callback(undefined);
      },
    });
    const webglAddon = new WebglAddon();
    term.loadAddon(webglAddon); */
    /*     var isWebglEnabled = false;
    try {
      const webgl = new WebglAddon();
      term.loadAddon(webgl);
      isWebglEnabled = true;
    } catch (e) {
      console.warn("WebGL addon threw an exception during load", e);
    } */
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    fitAddon.fit();

    var isWebglEnabled = false;
    try {
      const webgl = new WebglAddon();
      term.loadAddon(webgl);
      isWebglEnabled = true;
    } catch (e) {
      console.warn("WebGL addon threw an exception during load", e);
    }

    // Cancel wheel events from scrolling the page if the terminal has scrollback
    document.querySelector(".xterm").addEventListener("wheel", (e) => {
      if (term.buffer.active.baseY > 0) {
        e.preventDefault();
      }
    });
    function winResize() {
      fitAddon.fit();
    }

    window.onresize = winResize;

    /////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // prompt response variables
    var getResponse = false;
    var promptResponse = "";
    var questionIndex = 0;
    var questions = [];
    var promptReturn = async () => { };

    term.prompt = () => {
      term.write("\r\n# ");
    };
    function prompt(term) {
      command = "";
      term.write("\r\n# ");
    }
    function promptret(term, msg, f) {
      command = "";
      getResponse = true;
      promptResponse = "";
      promptReturn = f;

      term.write("\r\n" + msg);
    }
    function promptQuestionList(term, questions) {
      questionIndex = 0;
      getResponse = true;
      term.write("\r\n" + questions[0].question);
    }
    var command = "";
    var accountInfo;
    var commands = {
      help: {
        f: async () => {
          term.writeln(
            [
              "\n\r Welcome to the NFT Terminal!\n\r\n\r Here are the available commands:",
              ...Object.keys(commands).map(
                (e) => `  ${e.padEnd(10)} \n\r ${commands[e].description}`
              ),
            ].join("\n\r\n\r")
          );
          prompt(term);
        },
        description: "Prints this help message",
      },
      guide: {
        f: async () => {
          window.open("http://OctagonNFt.cloud/NFTTerminal/guide");
          term.prompt(term);
        },
        description: "Clears the terminal screen",
      },
      cls: {
        f: async () => {
          term.clear();
          term.prompt(term);
        },
        description: "Clears the terminal screen",
      },
      fullscreen: {
        f: async () => {
          document
            .getElementById("nftterm")
            .setAttribute("style", "width: auto; height: 100%");
          winResize();
          term.prompt(term);
        },
        description: "Expand the terminal window",
      },
      version: {
        f: async () => {
          term.writeln("\n\rNFT Terminal for Loopring L2");
          term.writeln("Version 0.1.3");
          term.writeln("by OctagonNFT\n\r");

          term.writeln(
            "Alternate NFT viewers and NFT ownership\n\rmay be required for certain commands and\n\rfunctionality to work.\n\r\n\rYou can collect this NFT with\n\rthe 'order' command!\n\r"
          );
          term.writeln("Use at your own risk!\n\r");
          term.writeln("@OctagonNFT | https://OctagonNFT.cloud\n\r");
          term.prompt(term);
        },
        description: "Prints the version number",
      },

      primary: {
        f: async () => {
          try {
            var primaryAccount = store.getState().terminal.primaryAccount;
            if (primaryAccount.length < 1) {
              term.writeln("Not connected to a wallet.");
            } else {
              term.writeln(primaryAccount);
            }
          } catch (exception) {
            term.writeln("Error: " + exception);
          }

          term.prompt(term);
        },
        description: "Prints your primary account address",
      },
      balances: {
        f: async () => {
          try {
            var state = store.getState().terminal;
            if (state.primaryAccount.length < 1) {
              term.writeln("Not connected to a wallet.");
            } else {
              term.write("Obtaining account info... ");
              const { accInfo } = await exchangeAPI.getAccount({
                owner: state.primaryAccount,
              });
              term.writeln("Done.");
              term.write("Obtaining exchange info... ");
              const exchangeInfo = await exchangeAPI.getExchangeInfo();

              term.writeln("Done.");
              term.write("Generating eddsaKey... ");
              var kSeed =
                "Sign this message to access Loopring Exchange: " +
                exchangeInfo.exchangeInfo.exchangeAddress +
                " with key nonce: " +
                (accInfo.keyNonce - 1);

              const eddsaKey = await sdk.generateKeyPair({
                web3: connectProvides.usedWeb3,
                address: accInfo.owner,
                keySeed: kSeed, //accInfo.keySeed,
                walletType: state.walletType,
                chainId: state.chainId,
              });
              term.writeln("Done.");
              term.write("Obtaining apiKey... ");
              const { apiKey } = await userAPI.getUserApiKey(
                {
                  accountId: accInfo.accountId,
                },
                eddsaKey.sk
              );

              term.writeln("Done.");
              term.write("Obtaining token info... ");
              const tokens = await exchangeAPI.getMixMarkets();
              term.writeln("Done.\n\r");
              //console.log(tokens);
              const nftBalances = await userAPI.getUserNFTBalances(
                {
                  accountId: accInfo.accountId,
                },
                apiKey
              );
              console.log(nftBalances);
              term.writeln("Total NFT Count: " + nftBalances.totalNum + "\n\r");
              nftBalances.userNFTBalances.forEach((nft) => {
                // if (
                // nft.nftId ===
                // "0x8b9a501168e3f15afc0aab1e6960d4457b221d6d75635484a94fbdcba6af0bf8"
                // ) {
                term.writeln("id: " + nft.id);
                term.writeln("tokenID: " + nft.tokenId);
                term.writeln("nftData: " + nft.nftData);
                term.writeln("tokenAddress: " + nft.tokenAddress);
                term.writeln("nftId: " + nft.nftId);
                term.writeln("nftType: " + nft.nftType);
                term.writeln("total: " + nft.total);
                term.writeln("locked: " + nft.locked);
                term.writeln("pending depost: " + nft.pending.deposit);
                term.writeln("pending withdraw: " + nft.pending.withdraw);
                term.writeln("deploymentStatus: " + nft.deploymentStatus);
                term.writeln("isCounterfactualNFT: " + nft.isCounterFactualNFT);
                term.writeln(" ");
                // }
              });

              const tokenBalances = await userAPI.getUserBalances(
                {
                  accountId: accInfo.accountId,
                  tokens: "0,1",
                },
                apiKey
              );
              console.log(tokenBalances);
              term.writeln(
                "\n\rETH total: " +
                this.calcEth(tokenBalances.userBalances["0"].total)
              );
              term.writeln(
                "ETH locked: " +
                this.calcEth(tokenBalances.userBalances["0"].locked)
              );
              term.writeln(
                "ETH pending deposit: " +
                this.calcEth(tokenBalances.userBalances["0"].pending.deposit)
              );
              term.writeln(
                "ETH pending withdraw: " +
                this.calcEth(tokenBalances.userBalances["0"].pending.withdraw)
              );
              term.writeln(
                "\n\rLRC total: " +
                this.calcEth(tokenBalances.userBalances["1"].total)
              );
              term.writeln(
                "LRC locked: " +
                this.calcEth(tokenBalances.userBalances["1"].locked)
              );
              term.writeln(
                "LRC pending deposit: " +
                this.calcEth(tokenBalances.userBalances["1"].pending.deposit)
              );
              term.writeln(
                "LRC pending withdraw: " +
                this.calcEth(tokenBalances.userBalances["1"].pending.withdraw)
              );
            }
          } catch (exception) {
            term.writeln("Error: " + exception);
          }

          term.prompt(term);
        },
        description: "Prints NFT and ETH/LRC balances",
      },
      ipfsCid0ToNftID: {
        f: async () => {
          promptReturn = async () => {
            if (questions.length > 0) {
              try {
                const nftIdn = nftAPI.ipfsCid0ToNftID(questions[0].response);
                term.writeln(nftIdn);
              } catch (exception) {
                term.writeln("Error: " + exception);
              }
            }
          };
          questions = [];
          var q1 = new promptQuestion();
          q1.question = "Enter IPFS CID:\r\n";

          questions.push(q1);

          promptQuestionList(term, questions);
          // term.prompt(term);
        },
        description: "Convert IPFS CIDs to NFT IDs.",
      },
      ipfsNftIDToCid: {
        f: async () => {
          promptReturn = async () => {
            if (questions.length > 0) {
              try {
                const nftIdn = nftAPI.ipfsNftIDToCid(questions[0].response);
                term.writeln(nftIdn);
              } catch (exception) {
                term.writeln("Error: " + exception);
              }
            }
          };
          questions = [];
          var q1 = new promptQuestion();
          q1.question = "Enter NFT ID:\r\n";

          questions.push(q1);

          promptQuestionList(term, questions);
          // term.prompt(term);
        },
        description: "Convert NFT IDs to IPFS CIDs.",
      },
      alltickers: {
        f: async () => {
          try {
            const response = await exchangeAPI.getAllTickers();
            term.writeln(JSON.stringify(response.raw_data));
          } catch (exception) {
            term.writeln("Error: " + exception);
          }

          term.prompt(term);
        },
        description: "Get Loopring L2 all tickers",
      },
      candles: {
        f: async () => {
          try {
            const response = await exchangeAPI.getCandlestick({
              market: "LRC-ETH",
              interval: sdk.TradingInterval.min15,
              limit: 20, //96,
            });
            /*             const candlestick: Candlestick = {
              timestamp: curTs,
              txs: parseInt(item[1]),
              open: parseFloat(item[2]),
              close: parseFloat(item[3]),
              high: parseFloat(item[4]),
              low: parseFloat(item[5]),
              baseVol: item[6],
              quoteVol: item[7],
            }; */
            term.writeln("LRC-ETH 15min candles");
            //response.candlesticks[0].forEach((element) => {
            //term.writeln(JSON.stringify(response.candlesticks[1]));
            response.candlesticks.forEach((candle) => {
              term.writeln("\n\rtimestamp: " + candle.timestamp);
              term.writeln("txs: " + candle.txs);
              term.writeln("open: " + candle.open);
              term.writeln("close: " + candle.close);
              term.writeln("high: " + candle.high);
              term.writeln("low: " + candle.low);
              term.writeln("baseVol: " + candle.baseVol);
              term.writeln("quoteVol: " + candle.quoteVol);
            });
            //  });
            // term.writeln(JSON.stringify(response));
          } catch (exception) {
            term.writeln("Error: " + exception);
          }

          term.prompt(term);
        },
        description: "Get 15min LRC-ETH candlesticks",
      },
      exchangeinfo: {
        f: async () => {
          try {
            const exchangeInfo = await exchangeAPI.getExchangeInfo();
            term.writeln(JSON.stringify(exchangeInfo));
          } catch (exception) {
            term.writeln("Error: " + exception);
          }

          term.prompt(term);
        },
        description: "Get Loopring L2 exchange info",
      },
      l2info: {
        f: async () => {
          var primaryAccount = store.getState().terminal.primaryAccount;
          if (primaryAccount.length < 1) {
            term.writeln("Not connected to a wallet.");
            term.prompt(term);
          } else {
            const { accInfo } = await exchangeAPI.getAccount({
              owner: primaryAccount,
            });
            term.writeln(JSON.stringify(accInfo));

            term.prompt(term);
          }
        },
        description: "Prints your L2 account info",
      },
      transferNFT: {
        f: async () => {
          //var primaryAccount = store.getState().terminal.primaryAccount;
          var state = store.getState().terminal;
          if (state.primaryAccount.length < 1) {
            term.writeln("Not connected to a wallet.");
            term.prompt(term);
          } else {
            promptReturn = async () => {
              if (
                questions.length > 2 &&
                (questions[3].response == "Y" || questions[3].response == "y")
              ) {
                try {




                  //qty

                  const tokenId = questions[0].response;
                  var transferQty = questions[1].response;

                  if (this.filterInt(transferQty) == -1) {
                    transferQty = "1";
                  }

                  const transferTo = questions[2].response;

                  term.writeln("Network: " + sdk.ChainId[state.chainId]);
                  term.writeln("tokenId: " + tokenId);
                  // term.writeln("nftData: " + questions[1].response);
                  term.writeln("Qty: " + transferQty);
                  term.writeln("From: " + state.primaryAccount);
                  term.writeln("To: " + transferTo + "\n\r");

                  term.write("Obtaining account info... ");
                  const { accInfo } = await exchangeAPI.getAccount({
                    owner: state.primaryAccount,
                  });
                  term.writeln("Done.");
                  term.write("Obtaining exchange info... ");
                  const exchangeInfo = await exchangeAPI.getExchangeInfo();

                  term.writeln("Done.");
                  term.write("Generating eddsaKey... ");
                  var kSeed =
                    "Sign this message to access Loopring Exchange: " +
                    exchangeInfo.exchangeInfo.exchangeAddress +
                    " with key nonce: " +
                    (accInfo.keyNonce - 1);

                  const eddsaKey = await sdk.generateKeyPair({
                    web3: connectProvides.usedWeb3,
                    address: accInfo.owner,
                    keySeed: kSeed, //accInfo.keySeed,
                    walletType: state.walletType,
                    chainId: state.chainId,
                  });
                  term.writeln("Done.");
                  term.write("Obtaining apiKey... ");
                  const { apiKey } = await userAPI.getUserApiKey(
                    {
                      accountId: accInfo.accountId,
                    },
                    eddsaKey.sk
                  );

                  term.writeln("Done.");

                  term.write("Obtaining token info... ");
                  const tokens = await exchangeAPI.getMixMarkets();
                  const nftBalances = await userAPI.getUserNFTBalances(
                    {
                      accountId: accInfo.accountId,
                    },
                    apiKey
                  );
                  // console.log(nftBalances);
                  //  term.writeln("Total NFT Count: " + nftBalances.totalNum + "\n\r");
                  var tokenData = {}
                  nftBalances.userNFTBalances.forEach((nft) => {
                    // if (
                    // nft.nftId ===
                    // "0x8b9a501168e3f15afc0aab1e6960d4457b221d6d75635484a94fbdcba6af0bf8"
                    // ) {
                    // term.writeln("id: " + nft.id);
                    // term.writeln("tokenID: " + nft.tokenId);
                    //  term.writeln("nftData: " + nft.nftData);
                    // term.writeln("tokenAddress: " + nft.tokenAddress);
                    // term.writeln("nftId: " + nft.nftId);
                    // term.writeln("nftType: " + nft.nftType);
                    //  term.writeln("total: " + nft.total);
                    // term.writeln("locked: " + nft.locked);
                    // term.writeln("pending depost: " + nft.pending.deposit);
                    // term.writeln("pending withdraw: " + nft.pending.withdraw);
                    // term.writeln("deploymentStatus: " + nft.deploymentStatus);
                    // term.writeln("isCounterfactualNFT: " + nft.isCounterFactualNFT);
                    //  term.writeln(" ");
                    tokenData[nft.tokenId] = nft.nftData;


                    // }
                  });
                  term.writeln("Done.");
                  term.write("Obtaining storageId... ");
                  const storageId = await userAPI.getNextStorageId(
                    {
                      accountId: accInfo.accountId,
                      sellTokenId: tokenId,
                    },
                    apiKey
                  );
                  term.writeln("Done.");

                  /*                   term.write("Obtaining NFT token address...");
                                    const counterFactualNftInfo = {
                                      nftOwner: accInfo.owner,
                                      nftFactory: sdk.NFTFactory[state.chainId],
                                      nftBaseUri: "",
                                    };
                  
                                    const nftTokenAddress =
                                      nftAPI.computeNFTAddress(counterFactualNftInfo)
                                        .tokenAddress || "";
                  
                                    term.writeln("Done.");
                                    term.writeln("\r\n" + nftTokenAddress + "\r\n"); */
                  term.write("Obtaining NFT offchain fee... ");
                  const fee = await userAPI.getNFTOffchainFeeAmt(
                    {
                      accountId: accInfo.accountId,
                      amount: transferQty,
                      requestType: sdk.OffchainNFTFeeReqType.NFT_TRANSFER,
                    },
                    apiKey
                  );
                  term.writeln("Done.");
                  const maxFeeAmt =
                    fee.fees["LRC"].fee ?? "9400000000000000000";
                  term.writeln(
                    "\r\n" +
                    JSON.stringify(fee.fees["LRC"]) +
                    "\r\n(" +
                    parseInt(maxFeeAmt.substring(0, maxFeeAmt.length - 16)) /
                    100 +
                    "LRC)\r\n"
                  );



                  // const nftIdn = nftAPI.ipfsCid0ToNftID(questions[0].response);

                  term.writeln("\r\nTransfering...\r\n");
                  const transferResult = await userAPI.submitNFTInTransfer({
                    request: {
                      exchange: exchangeInfo.exchangeInfo.exchangeAddress,
                      fromAccountId: accInfo.accountId,
                      fromAddress: accInfo.owner,
                      toAccountId: 0, // toAccountId is not required, input 0 as default
                      toAddress: transferTo,
                      token: {
                        tokenId: tokenId,
                        nftData: tokenData[tokenId],
                        amount: transferQty,
                      },
                      maxFee: {
                        tokenId: tokens.pairs.LRC.tokenId,
                        amount: maxFeeAmt,
                      },
                      storageId: storageId.offchainId ?? 9,
                      validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                    },
                    web3: connectProvides.usedWeb3,
                    chainId: state.chainId,
                    walletType: state.walletType,
                    eddsaKey: eddsaKey.sk,
                    apiKey,
                  });
                  //console.log("transfer Result:", transferResult);

                  /*                   const response = await userAPI.submitNFTMint({
                                      request: {
                                        exchange: exchangeInfo.exchangeInfo.exchangeAddress,
                                        minterId: accInfo.accountId,
                                        minterAddress: accInfo.owner,
                                        toAccountId: accInfo.accountId,
                                        toAddress: accInfo.owner,
                                        nftType: 0,
                                        tokenAddress: nftTokenAddress,
                                        nftId: nftIdn, //.toString(16),
                                        amount: questions[1].response,
                                        validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                                        storageId: storageId.offchainId ?? 9,
                                        maxFee: {
                                          tokenId: tokens.pairs.LRC.tokenId,
                                          amount: maxFeeAmt,
                                        },
                                        royaltyPercentage: parseInt(questions[2].response),
                                        counterFactualNftInfo: counterFactualNftInfo,
                                        forceToMint: false,
                                      },
                                      web3: connectProvides.usedWeb3,
                                      chainId: state.chainId,
                                      walletType: state.walletType,
                                      eddsaKey: eddsaKey.sk,
                                      apiKey: apiKey,
                                    }); */
                  term.writeln("response: " + JSON.stringify(transferResult));
                } catch (exception) {
                  term.writeln("Error: " + exception);
                  term.prompt(term);
                }
              }
            };

            questions = [];
            var q0 = new promptQuestion();
            q0.question = "Enter tokenId:\r\n";
            var q1 = new promptQuestion();
            q1.question = "Enter qty [1]:\r\n";
            var q2 = new promptQuestion();
            q2.question = "Enter Dest L2 Address:\r\n";
            var q3 = new promptQuestion();
            q3.question = "\r\nContinue transfer? (Y):\r\n";


            questions.push(q0);
            questions.push(q1);
            questions.push(q2);
            questions.push(q3);
            promptQuestionList(term, questions);
          }
        },
        description: "Transfer a NFT on Loopring L2",
      },
      mint: {
        f: async () => {
          //var primaryAccount = store.getState().terminal.primaryAccount;
          var state = store.getState().terminal;
          if (state.primaryAccount.length < 1) {
            term.writeln("Not connected to a wallet.");
            term.prompt(term);
          } else {
            promptReturn = async () => {
              if (
                questions.length > 2 &&
                (questions[3].response == "Y" || questions[3].response == "y")
              ) {
                try {
                  if (questions[0].response.startsWith("ipfs://")) {
                    questions[0].response = questions[0].response.substr(7);
                  }
                  if (this.filterInt(questions[1].response) == -1) {
                    questions[1].response = "1";
                  }
                  if (this.filterInt(questions[2].response) == -1) {
                    questions[2].response = "1";
                  }

                  term.writeln("Network: " + sdk.ChainId[state.chainId]);
                  term.writeln("Minter: " + state.primaryAccount);
                  term.writeln("baseURI: " + questions[0].response);
                  term.writeln("token qty: " + questions[1].response);
                  term.writeln("royalty: " + questions[2].response + "\n\r");
                  term.write("Obtaining account info... ");
                  const { accInfo } = await exchangeAPI.getAccount({
                    owner: state.primaryAccount,
                  });
                  term.writeln("Done.");
                  term.write("Obtaining exchange info... ");
                  const exchangeInfo = await exchangeAPI.getExchangeInfo();

                  term.writeln("Done.");
                  term.write("Generating eddsaKey... ");
                  var kSeed =
                    "Sign this message to access Loopring Exchange: " +
                    exchangeInfo.exchangeInfo.exchangeAddress +
                    " with key nonce: " +
                    (accInfo.keyNonce - 1);

                  const eddsaKey = await sdk.generateKeyPair({
                    web3: connectProvides.usedWeb3,
                    address: accInfo.owner,
                    keySeed: kSeed, //accInfo.keySeed,
                    walletType: state.walletType,
                    chainId: state.chainId,
                  });
                  term.writeln("Done.");
                  term.write("Obtaining apiKey... ");
                  const { apiKey } = await userAPI.getUserApiKey(
                    {
                      accountId: accInfo.accountId,
                    },
                    eddsaKey.sk
                  );

                  term.writeln("Done.");

                  term.write("Obtaining token info... ");
                  const tokens = await exchangeAPI.getMixMarkets();
                  term.writeln("Done.");
                  term.write("Obtaining storageId... ");
                  const storageId = await userAPI.getNextStorageId(
                    {
                      accountId: accInfo.accountId,
                      sellTokenId: tokens.pairs.LRC.tokenId,
                    },
                    apiKey
                  );
                  term.writeln("Done.");

                  term.write("Obtaining NFT token address...");
                  const counterFactualNftInfo = {
                    nftOwner: accInfo.owner,
                    nftFactory: sdk.NFTFactory[state.chainId],
                    nftBaseUri: "",
                  };

                  const nftTokenAddress =
                    nftAPI.computeNFTAddress(counterFactualNftInfo)
                      .tokenAddress || "";

                  term.writeln("Done.");
                  term.writeln("\r\n" + nftTokenAddress + "\r\n");
                  term.write("Obtaining NFT offchain fee... ");
                  const fee = await userAPI.getNFTOffchainFeeAmt(
                    {
                      accountId: accInfo.accountId,
                      tokenAddress: nftTokenAddress,
                      requestType: sdk.OffchainNFTFeeReqType.NFT_MINT,
                    },
                    apiKey
                  );
                  term.writeln("Done.");
                  const maxFeeAmt =
                    fee.fees["LRC"].fee ?? "9400000000000000000";
                  term.writeln(
                    "\r\n" +
                    JSON.stringify(fee.fees["LRC"]) +
                    "\r\n(" +
                    parseInt(maxFeeAmt.substring(0, maxFeeAmt.length - 16)) /
                    100 +
                    "LRC)\r\n"
                  );
                  const nftIdn = nftAPI.ipfsCid0ToNftID(questions[0].response);

                  term.writeln("\r\nMinting!\r\n");
                  /*                 const mintRequest = {
                  exchange: exchangeInfo.exchangeAddress,
                  minterId: accInfo.accountId,
                  minterAddress: accInfo.owner,
                  toAccountId: accInfo.accountId,
                  //toAddress: accInfo.owner,
                  nftType: 0,
                  tokenAddress: nftTokenAddress.toString(),
                  nftId: nftIdn.toString(16), //
                  //  "0xb802d1bc245a2490fb19fb3879b27000a0f82d01570d014337ee0a64625b6b1d".toString(
                  //    16
                  // ), //,
                  amount: "1", // questions[1].response,
                  validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                  storageId: 9, //storageId.offchainId ??
                  maxFee: {
                    tokenId: 1, // tokens.pairs.LRC.tokenId,
                    amount: fee.fees["LRC"].fee ?? "9400000000000000000",
                  },
                  royaltyPercentage: 1, //parseInt(questions[2].response),
                  //counterFactualNftInfo: counterFactualNftInfo,
                  //  forceToMint: false, // suggest use as false, for here is just for run test
                };
  

                const eddsaSig = get_EddsaSig_NFT_Mint(
                  mintRequest,
                  eddsaKey.sk
                );
                console.log("eddsaSig: " + eddsaSig);
 */

                  /*                 const requestOptions = {
                  method: "POST",
                  headers: { "x-api-key": apiKey },
                  body: JSON.stringify({
                    exchange: exchangeInfo.exchangeAddress,
                    minterId: accInfo.accountId,
                    minterAddress: accInfo.owner,
                    toAccountId: accInfo.accountId,
                    toAddress: accInfo.owner,
                    nftType: 0,
                    tokenAddress: nftTokenAddress,
                    nftId: nftIdn.toString(16),
                    amount: questions[1].response,
                    validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                    storageId: storageId.offchainId ?? 9, //
                    maxFee: {
                      tokenId: tokens.pairs.LRC.tokenId,
                      amount: fee.fees["LRC"].fee ?? "9400000000000000000",
                    },
                    royaltyPercentage: parseInt(questions[2].response),
                    counterFactualNftInfo: counterFactualNftInfo,
                    eddsaSignature: eddsaSig,
                  }),
                }; */
                  /*               const response = await fetch(
                  "https://api3.loopring.io/api/v3/nft/mint",
                  requestOptions
                );
                const data = await response.json();
                console.log(data); */
                  const response = await userAPI.submitNFTMint({
                    request: {
                      exchange: exchangeInfo.exchangeInfo.exchangeAddress,
                      minterId: accInfo.accountId,
                      minterAddress: accInfo.owner,
                      toAccountId: accInfo.accountId,
                      toAddress: accInfo.owner,
                      nftType: 0,
                      tokenAddress: nftTokenAddress,
                      nftId: nftIdn, //.toString(16),
                      amount: questions[1].response,
                      validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                      storageId: storageId.offchainId ?? 9,
                      maxFee: {
                        tokenId: tokens.pairs.LRC.tokenId,
                        amount: maxFeeAmt,
                      },
                      royaltyPercentage: parseInt(questions[2].response),
                      counterFactualNftInfo: counterFactualNftInfo,
                      forceToMint: false,
                    },
                    web3: connectProvides.usedWeb3,
                    chainId: state.chainId,
                    walletType: state.walletType,
                    eddsaKey: eddsaKey.sk,
                    apiKey: apiKey,
                  });
                  term.writeln("response: " + JSON.stringify(response));
                } catch (exception) {
                  term.writeln("Error: " + exception);
                  term.prompt(term);
                }
              }
            };

            questions = [];
            var q1 = new promptQuestion();
            q1.question = "Enter Metadata BaseURI CID:\r\n";
            var q2 = new promptQuestion();
            q2.question = "Enter # of tokens to mint (1-99999)[1]:\r\n";
            var q3 = new promptQuestion();
            q3.question = "Enter royalty percentage (0-10)[1]:\r\n";
            var q4 = new promptQuestion();
            q4.question = "\r\nContinue to mint? (Y):\r\n";
            questions.push(q1);
            questions.push(q2);
            questions.push(q3);
            questions.push(q4);
            promptQuestionList(term, questions);
          }
        },
        description: "Mint a NFT on Loopring L2",
      },
      order: {
        f: async () => {
          var state = store.getState().terminal;

          if (state.primaryAccount.length < 1) {
            term.writeln("Not connected to a wallet.");
            term.prompt(term);
          } else {
            promptReturn = async () => {
              try {
                const nftPrice = 80;
                if (this.filterInt(questions[0].response) == -1) {
                  questions[0].response = "1";
                }
                const orderQty = parseInt(questions[0].response);

                const { accInfo } = await exchangeAPI.getAccount({
                  owner: state.primaryAccount,
                });

                term.write("Obtaining exchange info... ");
                const exchangeInfo = await exchangeAPI.getExchangeInfo();

                term.writeln("Done.");
                term.write("Generating eddsaKey... ");
                var kSeed =
                  "Sign this message to access Loopring Exchange: " +
                  exchangeInfo.exchangeInfo.exchangeAddress +
                  " with key nonce: " +
                  (accInfo.keyNonce - 1);

                const eddsaKey = await sdk.generateKeyPair({
                  web3: connectProvides.usedWeb3,
                  address: accInfo.owner,
                  keySeed: kSeed, //accInfo.keySeed,
                  walletType: state.walletType,
                  chainId: state.chainId,
                });
                term.writeln("Done.");

                term.write("Obtaining apiKey... ");
                const { apiKey } = await userAPI.getUserApiKey(
                  {
                    accountId: accInfo.accountId,
                  },
                  eddsaKey.sk
                );

                term.writeln("Done.");
                term.write("Obtaining token info... ");
                const tokens = await exchangeAPI.getMixMarkets();
                term.writeln("Done.");
                term.write("Obtaining storageId... ");
                const storageId = await userAPI.getNextStorageId(
                  {
                    accountId: accInfo.accountId,
                    sellTokenId: tokens.pairs.LRC.tokenId,
                  },
                  apiKey
                );
                term.writeln("Done.");
                term.write("Obtaining fee... ");
                const fee = await userAPI.getOffchainFeeAmt(
                  {
                    accountId: accInfo.accountId,
                    requestType: sdk.OffchainFeeReqType.TRANSFER,
                  },
                  apiKey
                );

                term.writeln("Done.");
                if (questions[1].response.length > 100) {
                  questions[1].response = questions[1].response.substring(
                    0,
                    100
                  );
                }
                const tMemo =
                  "NFTTermL2 x " + orderQty + "  " + questions[1].response;
                const txAmt =
                  (orderQty * nftPrice).toString() + "000000000000000000";
                const transferResult = await userAPI.submitInternalTransfer({
                  request: {
                    exchange: exchangeInfo.exchangeInfo.exchangeAddress,
                    payerAddr: accInfo.owner,
                    payerId: accInfo.accountId,
                    payeeAddr: "0xb69cBe2fF674Cf98C745754b0aB2F1c24471889a",
                    payeeId: 108027,
                    storageId: storageId.offchainId,
                    token: {
                      tokenId: tokens.pairs.LRC.tokenId,
                      volume: txAmt, // 1000000000000000000 = 1 LRC
                    },
                    maxFee: {
                      tokenId: tokens.pairs.LRC.tokenId,
                      volume: fee.fees["LRC"].fee ?? "9400000000000000000",
                    },
                    validUntil: Math.round(Date.now() / 1000) + 30 * 86400,
                    memo: tMemo,
                  },
                  web3: connectProvides.usedWeb3,
                  chainId: state.chainId,
                  walletType: state.walletType,
                  eddsaKey: eddsaKey.sk,
                  apiKey: apiKey,
                });
                if (
                  transferResult.hash != null &&
                  transferResult.hash.length > 0
                ) {
                  term.writeln("\r\nStatus: " + transferResult.status);
                  term.writeln(
                    "Amount: " + (orderQty * nftPrice).toString() + " LRC"
                  );
                  term.writeln("NFT Qty: " + orderQty);
                  term.writeln("Memo: " + tMemo);
                  term.writeln("TX ID: " + transferResult.hash);
                  term.writeln(
                    "\r\n\r\nThanks!\r\nYour order will be processed as soon as\r\npossible. Be sure to check out all of\r\nour NFTs at https://OctagonNFT.cloud\r\n\r\nSend comments and feature requests\r\nto @OctagonNFT on Twitter"
                  );
                }
              } catch (exception) {
                term.writeln("Error: " + exception);
                term.prompt(term);
              }
            };
            questions = [];
            var q1 = new promptQuestion();
            q1.question =
              "Collect this NFT:\r\n\r\n1. Send 80 LRC to octagonnft.eth\r\n2. We'll send it back!\r\n\r\n\r\nHow many would you like to order? [1]:\r\n";
            var q2 = new promptQuestion();
            q2.question =
              "Anything you'd like to add in the memo:\r\nNFTTermL2 x {qty} ";

            questions.push(q1);
            questions.push(q2);

            promptQuestionList(term, questions);
          }
        },
        description: "Orders this NFT by sending 80 LRC to\r\n octagonnft.eth",
      },
      disconnect: {
        f: async () => {
          await this.walletDisconnect();
          term.writeln("Disconnected.");
          term.prompt(term);
        },
        description: "Disconnects your wallet",
      },
      /*       inwallet: {
        f: async () => {
          var Web3 = require("web3");
          var web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
          console.log(web3);
        },
        description: "Connects to window.ethereum wallet",
      }, */
      walletconnect: {
        f: async () => {
          await this.walletConnectConnect(term);
        },
        description: "Connect with WalletConnect",
      },
      coinbase: {
        f: async () => {
          await this.coinbaseConnect(term);
        },
        description: "Connect to the Coinbase wallet",
      },
      metamask: {
        f: async () => {
          await this.metaMaskConnect(term);
        },
        description: "Connect to the MetaMask wallet",
      },
      gamestop: {
        f: async () => {
          await this.gameStopConnect(term);
        },
        description: "Connect to the GameStop wallet",
      },
    };
    var currentState;
    var lastError;
    store.subscribe(() => {
      var newState = store.getState();
      //
      if (newState != currentState) {
        currentState = newState;
        if (
          currentState.terminal.commandResult.length > 0 &&
          currentState.terminal.commandResult[0].startsWith("Connected:")
        ) {
          clearCommandResult();
          term.writeln(" ");
          term.writeln(
            currentState.terminal.connectedWallet +
            " " +
            currentState.terminal.commandResult[0]
          );
          term.writeln(currentState.terminal.commandResult[1]);
          term.prompt(term);
        }

        if (
          currentState.terminal.commandResult.length > 0 &&
          currentState.terminal.commandResult[0].startsWith("Error:")
        ) {
          if (lastError != currentState.terminal.commandResult[1]) {
            lastError = currentState.terminal.commandResult[1];
            clearCommandResult();
            term.writeln(" ");

            term.writeln(
              "Error: " + JSON.stringify(currentState.terminal.commandResult[1])
            );
            if (
              //currentState.terminal.commandResult[1].opts.connectName ==
              //  "GameStop" &&
              currentState.terminal.commandResult[1].opts.error.code == 700004
            ) {
              //"User not installed GameStop extension"
              term.writeln(
                "\n\rYou might be sandboxed or are viewing\n\rthe terminal in the Gamestop wallet.\n\r\n\rThe GameStop Chrome extension functions\n\raren't currently available from within\n\rthe wallet's browser. You will need to\n\ruse the terminal in a browser window\n\rfor commands that need to prompt\n\rfor signatures:\r\n\r\nhttps://OctagonNFT.cloud/NFTTerminal\n\r\n\rhttps://OctagonNFT.cloud\n\r\n\r\n\rYou can still use commands like\n\r'candles' or 'exchangeinfo' in a\n\rsandbox window and/or without a\n\rconnected wallet."
              );
            }
            term.prompt(term);
          }
        }
      }
    });
    winResize();
    term.writeln(
      [
        "",
        "\x1b[38;5;242m  ┌──────────────────────────────────┐",
        "  │                                  │",
        "  │                                  │",
        "  │ \x1b[38;5;253m          █▄ █ █▀ ▀█▀            \x1b[38;5;242m│",
        "  │ \x1b[38;5;248m          █ ▀█ █▀  █             \x1b[38;5;242m│",
        "  │                                  │",
        "  │ \x1b[38;5;253m▀█▀ ██▀ █▀▄ █▄ ▄█ █ █▄ █ ▄▀▄ █   \x1b[38;5;242m│",
        "  │ \x1b[38;5;248m █  █▄▄ █▀▄ █ ▀ █ █ █ ▀█ █▀█ █▄▄ \x1b[38;5;242m│",

        "  │                                  │",
        "  │       for \x1b[38;5;27m◀▷◸ Loopring L2\x1b[38;5;242m        │",
        "  │                                  │",
        "  │                                  │",
        "  └────────────────────by \x1b[38;5;47mOctagon\x1b[0mNFT\x1b[38;5;242m─┘\x1b[0m",
        "",
        "",
      ].join("\n\r")
    );

    term.writeln("Type `guide` or `help` to get started.");
    prompt(term);

    //////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
    // keypress event
    term.onData(async (e) => {
      switch (e) {
        case "\u0003": // Ctrl+C
          term.write("^C");
          prompt(term);
          break;
        case "\r": // Enter
          if (getResponse == true) {
            questions[questionIndex].response = promptResponse;
            promptResponse = "";
            term.writeln(" ");
            questionIndex++;
            if (questionIndex >= questions.length) {
              getResponse = false;
              term.writeln(" ");
              await promptReturn();
              term.prompt(term);
            } else {
              term.write(
                "\r\n" +
                questions[questionIndex].question.replace(
                  "{qty}",
                  questions[0].response || 1
                )
              );
            }
          } else {
            await runCommand(term, command);
            command = "";
          }

          break;
        case "\u007F": // Backspace (DEL)
          // Do not delete the prompt
          if (term._core.buffer.x > 2 || getResponse) {
            term.write("\b \b");
            if (getResponse == true) {
              if (promptResponse.length > 0) {
                promptResponse = promptResponse.substr(
                  0,
                  promptResponse.length - 1
                );
              }
            } else {
              if (command.length > 0) {
                command = command.substr(0, command.length - 1);
              }
            }
          }
          break;
        default:
          if (
            (e >= String.fromCharCode(0x20) &&
              e <= String.fromCharCode(0x7e)) ||
            e >= "\u00a0"
          ) {
            if (getResponse == true) {
              promptResponse += e;
            } else {
              command += e;
            }

            term.write(e);
          }
      }
    });
    async function runCommand(term, text) {
      const command = text.trim().split(" ")[0];
      if (command.length > 0) {
        term.writeln("");
        if (command in commands) {
          await commands[command].f();
          return;
        }
        term.writeln(`${command}: command not found`);
      }
      // console.log(command);

      prompt(term);
    }

    term.registerLinkProvider({
      provideLinks(bufferLineNumber, callback) {
        switch (bufferLineNumber) {
          case 14:
            callback([
              {
                text: "OctagonNFT",
                range: { start: { x: 27, y: 14 }, end: { x: 36, y: 14 } },
                activate() {
                  window.open("https://OctagonNFT.cloud", "_blank");
                },
              },
            ]);
            return;
        }
        callback(undefined);
      },
    });
    // term.setOption("cursorBlink", true);
    //term.setOption("cols", 60);
    //term.setOption("rows", 20);
    // term.setOption("fontFamily", '"Cascadia Code", Menlo, monospace');
    // term.setOption("fontSize", 12);
  }
  // render() {
  //   return (
  //     <>
  //       {/* Create a new terminal and set it's ref. */}
  //       <div id="nft">
  //         <XTerm
  //           ref={this.xtermRef}
  //           options={{
  //             fontFamily: '"Cascadia Code", Menlo, monospace',
  //             fontSize: 12,
  //             cursorBlink: true,
  //             cols: 60,
  //             rows: 20,
  //           }}
  //           onData={(data) => {
  //             const code = data.charCodeAt(0);
  //             // If the user hits empty and there is something typed echo it.
  //             if (code === 13 && this.state.input.length > 0) {
  //               this.xtermRef.current.terminal.write(
  //                 "\r\nYou typed: '" + this.state.input + "'\r\n"
  //               );
  //               this.xtermRef.current.terminal.write("echo> ");
  //               this.setState({ input: "" });
  //             } else if (code < 32 || code === 127) {
  //               // Disable control Keys such as arrow keys
  //               return;
  //             } else {
  //               // Add general key press characters to the terminal
  //               this.xtermRef.current.terminal.write(data);
  //               this.setState({ input: this.state.input + data });
  //             }
  //           }}
  //         />
  //       </div>
  //     </>
  //   );
  // }
  /*   componentDidMount() {
    var term = new Terminal({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      fontSize: 12,
      // theme: baseTheme,
      cursorBlink: true,
      cols: 60,
      rows: 20,
    });
    term.open(document.getElementById("terminal"));
    var shellprompt = "$ ";

    term.prompt = function () {
      term.write("\r\n" + shellprompt);
    };
  } */
  calcEth(value) {
    return parseInt(value.substring(0, value.length - 10)) / 100000000 || 0;
  }
  getBadgeClasses() {
    let classes = "badge m-2 ";
    classes += this.state.count === 0 ? "bg-warning" : "bg-prinary";
    return classes;
  }

  formatCount() {
    const { count } = this.state;

    return count === 0 ? "Zero" : count;
  }
  filterInt(value) {
    if (/^[-+]?(\d+|Infinity)$/.test(value)) {
      return Number(value);
    } else {
      return -1;
    }
  }
}

export default NftTerminal;
